import React, { useEffect, useState } from "react"
import styled from "@emotion/styled"
import { injectIntl } from "gatsby-plugin-intl"
import { isBrowser } from "../../context/ApolloContext"
import { useLocation } from "@reach/router"
import { isLoadedScript } from "../../utils/additionalScriptHelpers"

const KlarnaPlacementSectionWrapper = styled.div`
  width: 100%;
    min-height: 42px;
`

const KlarnaPlacementSection = ({ intl, dataKey, price, theme }) => {
  const { pathname } = useLocation()

  const klarnaPrice = price => {
    return price * 100
  }

  const klarnaScript = () => {
    let srcUrl = "https://eu-library.klarnaservices.com/lib.js"
    if (!isLoadedScript(srcUrl)) {
      const script = document.createElement("script")
      script.src = srcUrl
      script.setAttribute(
        "data-client-id",
        "3c1de5d8-3870-5c76-be1a-9f92f36fb078"
      )
      document.body.appendChild(script)
    }
  }

  useEffect(() => {
    if (isBrowser) {
      window.KlarnaOnsiteService = window.KlarnaOnsiteService || []
      window.KlarnaOnsiteService.push({ eventName: "refresh-placements" })
      // window.Klarna.OnsiteMessaging.refresh()
    }
  }, [pathname])

  useEffect(() => {
    klarnaScript()
  }, [])
  return (
    <KlarnaPlacementSectionWrapper>
      {dataKey === "homepage-promotion-wide" && (
        <klarna-placement
          data-key="homepage-promotion-wide"
          data-locale="en-GB"
        ></klarna-placement>
      )}
      {dataKey === "top-strip-promotion-badge" && (
        <klarna-placement
          data-key="top-strip-promotion-badge"
          data-locale="en-GB"
          data-theme="custom"
        ></klarna-placement>
      )}
      {theme && dataKey === "top-strip-promotion-auto-size" && (
        <klarna-placement
          data-key="top-strip-promotion-auto-size"
          data-locale="en-GB"
          data-theme={theme}
        ></klarna-placement>
      )}
      {price && dataKey === "credit-promotion-auto-size" && (
        <klarna-placement
          data-key="top-strip-promotion-auto-size"
          data-locale="en-GB"
        ></klarna-placement>
      )}
      {price && dataKey === "credit-promotion-badge" && (
        <klarna-placement
          data-key="credit-promotion-badge"
          data-locale="en-GB"
          data-purchase-amount={klarnaPrice(price)}
          data-theme="custom"
        ></klarna-placement>
      )}
    </KlarnaPlacementSectionWrapper>
  )
}

export default injectIntl(KlarnaPlacementSection)
