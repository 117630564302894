import React, { Fragment } from "react"
import styled from "@emotion/styled"
import ReactTooltip from "react-tooltip"
import KlarnaPlacementSection from "../../KlarnaPlacementSection"
import { injectIntl } from "gatsby-plugin-intl"

const Testimonials = ({ intl, data }) => {
  const storeUrl = pageName => {
    let storePath = ""
    if (process.env.GATSBY_ACTIVE_ENV === "eur") {
      if (intl.locale === "fr") {
        storePath = process.env.GATSBY_ACTIVE_ENV + `/${pageName}/`
      } else {
        storePath =
          process.env.GATSBY_ACTIVE_ENV + `/${intl.locale}/${pageName}/`
      }
    } else if (process.env.GATSBY_ACTIVE_ENV === "cad") {
      if (intl.locale === "en") {
        storePath = process.env.GATSBY_ACTIVE_ENV + `/${pageName}/`
      } else {
        storePath =
          process.env.GATSBY_ACTIVE_ENV + `/${intl.locale}/${pageName}/`
      }
    } else if (process.env.GATSBY_ACTIVE_ENV === "gbp") {
      storePath = process.env.GATSBY_ACTIVE_ENV + `/${pageName}/`
    } else if (process.env.GATSBY_ACTIVE_ENV === "usd") {
      storePath = process.env.GATSBY_ACTIVE_ENV + `/${pageName}/`
    } else {
      if (intl.locale === "fr") {
        storePath = `${pageName}/`
      } else {
        storePath = `${intl.locale}/${pageName}/`
      }
    }
    return storePath
  }
  return (
    <Fragment>
      <Wrapper>
        <Container>
          {data.map((item, index) => (
            <Testimonial key={index.toString()}>
              <Image className="lazyload" data-src={item.image} alt="" />
              {item.text === "FREE<br/> Delivery" &&
              process.env.GATSBY_ACTIVE_ENV === "gbp" ? (
                <Text>
                  FREE delivery <br />
                  from the UK
                </Text>
              ) : (
                <Text
                  dangerouslySetInnerHTML={{
                    __html: item.text.replace("contact/", storeUrl("contact")),
                  }}
                />
              )}
              <ReactTooltip />
            </Testimonial>
          ))}
        </Container>
      </Wrapper>
      {process.env.GATSBY_ACTIVE_ENV === "gbp" && (
        <KlarnaPlacementSection
          dataKey="top-strip-promotion-auto-size"
          theme="dark"
        />
      )}
    </Fragment>
  )
}

const Wrapper = styled.div`
  display: flex;
  /* flex-direction: column; */
  min-height: 50px;
  width: 100%;
  padding: 20px 50px 20px 30px;
  background: ${props => props.theme.colors.white.light};
  align-items: center;

  @media (max-width: 550px) {
    flex-direction: column;
    padding: 20px;
  }
`

const Container = styled.div`
  display: flex;
  width: 100%;
  max-width: 1080px;
  margin: 0px auto;
  align-items: center;

  @media (max-width: 850px) {
    flex-wrap: wrap;
  }

  @media (max-width: 550px) {
    flex-direction: column;
  }
`

const Testimonial = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  object-fit: contain;
  flex-direction: row;

  @media (max-width: 850px) {
    flex-basis: 50%;
    padding: 10px 0px;
    justify-content: flex-start;
  }

  @media (max-width: 550px) {
    justify-content: left;
    width: 100%;
    margin: 5px 0px;
    padding: 0px;
  }

  .__react_component_tooltip {
    background: linear-gradient(
      145deg,
      rgb(93, 185, 188) 10%,
      rgb(42, 161, 169) 100%
    );
    color: #fff;
    max-width: 300px;
  }

  .__react_component_tooltip.show {
    opacity: 1;
  }
  .__react_component_tooltip.place-left:after {
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    right: -6px;
    top: 50%;
    margin-top: -4px;
    border-left-color: #5db9bc;
    border-left-style: solid;
    border-left-width: 6px;
  }
  .__react_component_tooltip.place-right:after {
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    left: -6px;
    top: 50%;
    margin-top: -4px;
    border-right-color: #5db9bc;
    border-right-style: solid;
    border-right-width: 6px;
  }
  .__react_component_tooltip.place-top:after {
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    bottom: -6px;
    left: 50%;
    margin-left: -8px;
    border-top-color: #5db9bc;
    border-top-style: solid;
    border-top-width: 6px;
  }
  .__react_component_tooltip.place-bottom:after {
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    top: -6px;
    left: 50%;
    margin-left: -8px;
    border-bottom-color: #5db9bc;
    border-bottom-style: solid;
    border-bottom-width: 6px;
  }
`

const Image = styled.img`
  height: 40px;
  object-fit: contain;
  margin-bottom: 0;
  width: 40px;
`

const Text = styled.span`
  justify-content: center;
  align-items: center;
  margin-left: 15px;

  @media (max-width: 550px) {
    margin-left: 50px;
    font-weight: bold;
  }

  span.opentooltipinfo {
    text-transform: lowercase;
    padding: 0px 0px;
    height: 15px;
    width: 15px;
    font-size: 11px;
    font-weight: bold;
    border-radius: 50%;
    border: 2px solid #47bebd;
    color: #47bebd;
    display: inline-flex;
    align-items: center;
    position: relative;
    justify-content: center;
    cursor: pointer;
    top: -1px;

    @media (max-width: 550px) {
      top: -3px;
    }
  }

  a {
    text-decoration: underline;
  }
`

export default injectIntl(Testimonials)
